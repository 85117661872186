<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <Grid
            class="grid-full-height"
            :data-items="result"
            :columns="columns"
            :column-menu="true"
            :take="dataState.take"
            :skip="dataState.skip"
            :sort="dataState.sort"
            :filter="dataState.filter"
            :group="dataState.group"
            :sortable="true"
            :reorderable="true"
            :resizable="true"
            :groupable="false"
            :pageable="true"
            :filterable="true"
            :page-size="50"
            @datastatechange="onDataStateChange"
          >
            <template v-slot:cellActionTemplate="{ props }">
              <DetailLinkActionCellTemplate
                :field="props.field"
                :row-type="props.rowType"
                :class-name="props.className"
                @click="openHealthIssueDetails(props.dataItem.id)"
              />
            </template>

            <template v-slot:messageCell="{ props }">
              <TextPopupCellTemplate
                :id="props.dataItem.id"
                :field="props.field"
                :row-type="props.rowType"
                popover-title="Message"
                :text="props.dataItem.message"
                :preview-length="200"
              />
            </template>

          </Grid>
        </b-card>
      </b-col>
    </b-row>

    <b-sidebar
      v-model="showSidebar"
      width="400px"
      right
      shadow
      lazy
    >
      <div
        v-if="selectedHealthIssue"
        class="px-3 py-2"
      >
        <dl>
          <dt>Timestamp</dt>
          <dd>{{ selectedHealthIssue.postedTime | formatDateTimeTz(asup.timezone) }}</dd>

          <dt>Severity</dt>
          <dd>{{ selectedHealthIssue.severity }}</dd>

          <dt>Category</dt>
          <dd>{{ selectedHealthIssue.category }}</dd>
        </dl>

        <hr>

        <dl>
          <dt>Message</dt>
          <dd>{{ selectedHealthIssue.message }}</dd>

          <template v-if="selectedHealthIssue.detailedDescription">
            <dt>Detailed Description</dt>
            <dd>{{ selectedHealthIssue.detailedDescription }}</dd>
          </template>

          <template v-if="selectedHealthIssue.responseAction">
            <dt>Response Action</dt>
            <dd>{{ selectedHealthIssue.responseAction }}</dd>
          </template>
        </dl>
      </div>
    </b-sidebar>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BSidebar,
} from 'bootstrap-vue'
import { Grid } from '@progress/kendo-vue-grid'
import { process } from '@progress/kendo-data-query'
import {
  GridDefaultMixin,
} from '@/mixins/grid'
import {
  TextPopupCellTemplate, DetailLinkActionCellTemplate,
} from '@/components/grid'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSidebar,
    Grid,
    TextPopupCellTemplate,
    DetailLinkActionCellTemplate,
  },
  mixins: [
    GridDefaultMixin,
  ],
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showSidebar: false,
      selectedHealthIssue: null,
      gridId: 'ppdm_healthissues',
      dataState: {
        sort: [{
          field: 'localPostedTime',
          dir: 'desc',
        }],
      },
      columns: [
        {
          cell: 'cellActionTemplate',
          field: 'cellActionTemplate',
          locked: true,
          title: ' ',
          filterable: false,
          sortable: false,
          groupable: false,
          width: '40px',
          orderIndex: 0,
          columnMenu: false,
          static: true,
        },
        {
          field: 'deductionScoreString',
          title: 'Deduction',
          orderIndex: 1,
          width: '100px',
          filter: 'number',
          type: 'number',
          filterable: false,
          sortable: true,
          columnMenu: false,
        },
        {
          field: 'message',
          title: 'Issue',
          orderIndex: 2,
          width: '600px',
          filter: 'string',
          type: 'string',
          filterable: false,
          sortable: false,
          columnMenu: false,
          cell: 'messageCell',
        },
        {
          field: 'category',
          title: 'Category',
          orderIndex: 3,
          width: '200px',
        },
        {
          field: 'component',
          title: 'Component',
          orderIndex: 4,
          width: '200px',
        },
        {
          field: 'localPostedTime',
          title: 'Date',
          orderIndex: 6,
          width: '200px',
          filter: 'date',
          type: 'date',
          format: '{0:g}',
        },
      ],
    }
  },
  computed: {
    result() {
      const data = this.asup.details.metrics.systemHealthIssues
      data.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.localPostedTime = this.$options.filters.formatDateTimeTz(item.postedTime, this.asup.timezone)
        // eslint-disable-next-line no-param-reassign
        item.deductionScoreString = `${item.scoreDeduction}` * -1
      })

      return process(data, this.dataState)
    },
  },
  methods: {
    onDataStateChange(e) {
      this.dataState = e.data
    },
    openHealthIssueDetails(healthIssueId) {
      this.selectedHealthIssue = this.asup.details.metrics.systemHealthIssues.find(x => x.id === healthIssueId)
      this.showSidebar = true
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
</style>
